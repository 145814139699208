import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import "./privacy.style.css";
import background from "../../assets/privacy-policy.jpg";

function PrivacyPolicy() {
  return (
    <div>
      <div
        className="image-banner"
        style={{
          background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("${background}")`,
        }}
      >
        <div className="container">
          <div className="col-md-12">
            <div className="lg-text">Privacy Policy</div>
          </div>
        </div>
      </div>

      <div className="padded-divider-20"></div>
      <MDBContainer>
        <p>
          This Privacy Policy describes how we collect, use, disclose, and
          protect personal information when you use our software, including any
          related websites, services, or products (collectively referred to as
          the "Software").
        </p>

        <p className="title">Information We Collect</p>

        <p>We may collect the following types of personal information:</p>

        <ul>
          <li>
            1.1. Personal Information You Provide: When you use our Software,
            you may provide us with certain personal information such as your
            name, email address, username, password, and any other information
            you voluntarily provide.
          </li>
          <li>
            1.2. Automatically Collected Information: We may automatically
            collect certain information about your device and usage of the
            Software. This may include your IP address, device type, operating
            system, browser type, and usage data such as pages visited and
            actions taken within the Software.
          </li>
        </ul>

        <p className="title">Use of Information</p>
        <p>
          We use the personal information we collect for the following purposes:
        </p>

        <ul>
          <li>
            2.1. To Provide and Improve the Software: We use the information to
            provide, personalize, and improve the Software, including developing
            new features, conducting analytics, and enhancing user experience.
          </li>
          <li>
            2.2. Communication: We may use your email address to send you
            important updates, notifications, and other communications related
            to the Software. You can opt-out of receiving certain communications
            by following the instructions provided in such communications.
          </li>
          <li>
            2.3. Legal Compliance: We may use your personal information to
            comply with applicable laws, regulations, legal processes, or
            governmental requests.
          </li>
        </ul>

        <p className="title">Sharing of Information</p>

        <p>
          We may share your personal information in the following circumstances:
        </p>

        <ul>
          <li>
            3.1. Service Providers: We may engage trusted third-party service
            providers who assist us in operating and managing the Software.
            These service providers have access to your personal information
            only to perform specific tasks on our behalf and are obligated to
            keep your information confidential.
          </li>
          <li>
            3.2. Business Transfers: If we are involved in a merger,
            acquisition, or sale of all or a portion of our assets, your
            personal information may be transferred as part of that transaction.
            We will notify you via email and/or prominent notice within the
            Software of any such change in ownership or control of your personal
            information.
          </li>
          <li>
            3.3. Legal Requirements: We may disclose your personal information
            if required to do so by law or in response to valid requests from
            public authorities (e.g., a court or a government agency).
          </li>
        </ul>

        <p className="title">Data Security</p>

        <p>
          We take reasonable measures to protect your personal information from
          unauthorized access, use, or disclosure. However, please be aware that
          no method of transmission over the internet or electronic storage is
          100% secure, and we cannot guarantee absolute security.
        </p>

        <p className="title">Your Rights</p>
        <p>
          You have certain rights regarding your personal information, which may
          include:
        </p>

        <ul>
          <li>
            5.1. Access and Correction: You can access and update your personal
            information within the Software's settings or by contacting us
            directly.
          </li>
          <li>
            5.2. Opt-Out: You can opt-out of receiving certain communications
            from us by following the instructions provided in the communication
            or by contacting us.
          </li>
          <li>
            5.3. Data Deletion: You can request the deletion of your personal
            information by contacting us. However, please note that we may need
            to retain certain information for legal or legitimate business
            purposes.
          </li>
        </ul>

        <p className="title">Children's Privacy</p>
        <p>
          The Software is not intended for use by individuals under the age of
          [13/16/18, depending on the applicable legal requirements]. We do not
          knowingly collect personal information from children without
          appropriate parental or guardian consent. If you believe we have
          collected personal information from a child without parental consent,
          please contact us, and we will take steps to remove such information.
        </p>

        <p className="title">Changes to the Privacy Policy</p>
        <p>
          We reserve the right to update or modify this Privacy Policy at any
          time. We will notify you of any material changes by posting the
          updated Privacy Policy within the Software or by sending you an email.
        </p>
      </MDBContainer>
      <div className="padded-divider-20"></div>
    </div>
  );
}

export default PrivacyPolicy;
