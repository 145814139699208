import React, { useCallback, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBTextArea,
} from "mdb-react-ui-kit";
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from "react-google-recaptcha-v3";
import contact_img from "../../assets/contact_us.jpg";

function ContactUsForm() {
  const [formData, setFormData] = useState({
    firstname: "",
    lastName: "",
    emailAddress: "",
    message: "",
  });

  function handleDataChange(event) {
    const { name, value } = event.target;

    setFormData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  const recaptchaSiteKey = "6LderpcmAAAAAOVutvfPAqWSbW_CPSCCFj8mb_I9";
  const recaptchaSecretKey = "6LderpcmAAAAAM6hfuK3fsEbUg7fMnTl9U_h6Aza";

  const [token, setToken] = useState();
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const onVerify = useCallback((token) => {
    setToken(token);
  });

  const submitForm = () => {
    setRefreshReCaptcha(r => !r);
    console.log(formData);
  }

  const resetForm = () => {
    setFormData({
        firstname: "",
        lastName: "",
        emailAddress: "",
        message: "",
      });
  }

  return (
    <div>
      <GoogleReCaptchaProvider
        reCaptchaKey={recaptchaSiteKey}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
        container={{
          // optional to render inside custom element
          element: "grcp",
          parameters: {
            badge: "inline", // optional, default undefined
          },
        }}
      ></GoogleReCaptchaProvider>
      <GoogleReCaptchaProvider reCaptchaKey={recaptchaSecretKey}>
        <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
      </GoogleReCaptchaProvider>
      

      <MDBContainer>
        <MDBRow className="d-flex justify-content-center align-items-center h-100">
          <MDBCol>
            <MDBCard className="my-4">
              <MDBRow className="g-0">
                <MDBCol md="6" className="d-none d-md-block">
                  <MDBCardImage
                    src={contact_img}
                    alt="Contact Us Image"
                    className="rounded-start"
                    fluid
                  />
                </MDBCol>
                <MDBCol md="6">
                  <MDBCardBody className="text-black d-flex flex-column justify-content-center">
                    <h3 className="mb-5 text-uppercase fw-bold">Contact Us</h3>
                    <MDBRow>
                      <MDBCol md="6">
                        <MDBInput
                          wrapperClass="mb-4"
                          label="First Name"
                          size="lg"
                          name="firstname"
                          type="text"
                          value={formData.firstname}
                          onChange={handleDataChange}
                        />
                      </MDBCol>
                      <MDBCol md="6">
                        <MDBInput
                          wrapperClass="mb-4"
                          label="Last Name"
                          size="lg"
                          name="lastName"
                          type="text"
                          value={formData.lastName}
                          onChange={handleDataChange}
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Email Address"
                      size="lg"
                      name="emailAddress"
                      type="email"
                      value={formData.emailAddress}
                      onChange={handleDataChange}
                    />
                    <MDBTextArea
                      wrapperClass="mb-4"
                      label="Message"
                      size="lg"
                      name="message"
                      type="text"
                      rows={5}
                      onChange={handleDataChange}
                      value={formData.message}
                    ></MDBTextArea>

                    <div className="d-flex justify-content-end pt-3">
                    <span id="grcp"></span>
                      <MDBBtn color="light" size="lg" onClick={resetForm}>
                        Reset all
                      </MDBBtn>
                      <MDBBtn className="ms-2" color="warning" size="lg" onClick={submitForm}>
                        Send
                      </MDBBtn>
                    </div>

                    
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </div>
  );
}

export default ContactUsForm;
