import React from "react";
import "./service-card.style.css";

function ServiceCard(props) {
  return (
    <div className="col-lg-6 col-sm-6">
      <div className="card" style={{ minHeight: "350px"}}>
        <div className="card-body">
          <i className={props.icon}></i>
          <h2 className="card-title">{props.title}</h2>
          <p className="card-text">{props.text}</p>
        </div>
      </div>
      <div className="padded-divider-20"></div>
    </div>
  );
}

export default ServiceCard;
