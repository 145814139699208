import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import AboutUs from "../components/about-us/about-us";

function HomePage() {
  return (
    <div>
      <Header />

      <AboutUs />

      <Footer />
    </div>
  );
}

export default HomePage;
