import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import CookiePolicy from "../components/cookie/cookie";

function CookiePage() {
    return <div>
        <Header />
        <CookiePolicy />
        <Footer />
    </div>
}

export default CookiePage;