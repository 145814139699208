import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import "./about-us.style.css";
import background from "../../assets/about_image.jpg";

function AboutUs() {
  return (
    <div>
      <div className="image-banner" style={{ background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("${background}")`}}>
        <div className="container">
          <div className="col-md-12">
            <div className="lg-text">About Us</div>
            <p className="image-para">
              Welcome to Geekible, your premier destination for cutting-edge
              mobile app development solutions.
            </p>
          </div>
        </div>
      </div>

      <MDBContainer>
        <div className="padded-divider-20"></div>
        <p>
          With a passion for innovation and a commitment to excellence, we are
          dedicated to helping businesses and individuals transform their ideas
          into engaging and functional mobile applications.
        </p>
        <p>
          At Geekible, we understand the power and influence that mobile apps
          have in today's digital landscape. We believe that a well-designed and
          seamlessly performing app can be a game-changer, enhancing user
          experiences, boosting brand recognition, and driving business growth.
          Our talented team of developers, designers, and strategists work
          tirelessly to deliver custom-built mobile applications that align with
          our clients' unique visions and objectives.
        </p>
        <p>
          What sets us apart is our unwavering focus on quality and user-centric
          design. We believe that a successful app should not only look visually
          appealing but should also provide a seamless and intuitive user
          experience. Our team works closely with clients to understand their
          target audience, business goals, and specific requirements to craft
          apps that resonate with users and leave a lasting impact.
        </p>
        <p>
          Whether you're a startup looking to make a grand entrance into the
          mobile app arena or an established company seeking to expand your
          digital presence, we have the expertise and experience to bring your
          app idea to life. Our extensive knowledge spans across various
          platforms, including iOS, Android, and hybrid frameworks, ensuring
          that we can develop apps that cater to a wide range of devices and
          operating systems.
        </p>
        <p>
          We take pride in our collaborative approach, working hand in hand with
          our clients throughout the entire development process. From
          conceptualization and design to development, testing, and deployment,
          we keep our clients informed and engaged at every step. Our
          transparent communication channels ensure that you have full
          visibility into the progress of your project and can provide feedback
          and suggestions along the way.
        </p>
        <p>
          Client satisfaction is at the heart of everything we do. We strive to
          build long-lasting relationships with our clients, going above and
          beyond to deliver exceptional results. Our dedication to excellence
          has earned us a reputation for delivering on-time, within-budget
          projects that exceed expectations.
        </p>
        <p>
          Thank you for considering Geekible as your mobile app development
          partner. We look forward to collaborating with you and turning your
          app dreams into reality. Together, let's shape the future of mobile
          technology and unlock the potential of your business.
        </p>
      </MDBContainer>
      <div className="padded-divider-20"></div>
    </div>
  );
}

export default AboutUs;
