import './App.css';
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import HomePage from './pages/home';
import UnsubPage from './pages/unsub';
import WhatWeDoPage from './pages/what-we-do';
import ContactUsPage from './pages/contact-us';
import PrivacyPage from './pages/privacy';
import CookiePage from './pages/cookie';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/what-we-do" element={<WhatWeDoPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="/unsub" element={<UnsubPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        <Route path="/cookie-policy" element={<CookiePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
