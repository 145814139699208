import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";
import "./cookie.style.css";
import background from "../../assets/cookie-policy.jpg";

function CookiePolicy() {
  return (
    <div>
      <div
        className="image-banner"
        style={{
          background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("${background}")`,
        }}
      >
        <div className="container">
          <div className="col-md-12">
            <div className="lg-text">Cookie Policy</div>
          </div>
        </div>
      </div>

      <div className="padded-divider-20"></div>
      <MDBContainer>
        <p>
          This Cookie Policy explains how our website uses cookies to provide
          you with an enhanced browsing experience and personalized content. By
          continuing to use our website, you consent to the use of cookies as
          described in this policy.
        </p>

        <p className="title">What are cookies?</p>
        <p>
          Cookies are small text files that are placed on your computer or
          mobile device when you visit a website. They are widely used to make
          websites work efficiently and to provide information to website
          owners.
        </p>

        <p className="title">How we use cookies</p>
        <p>We use cookies to:</p>

        <ul>
          <li>
            <em>Ensure website functionality:</em> We use essential cookies that
            are necessary for the operation of our website. These cookies enable
            you to navigate the website and use its features, such as accessing
            secure areas or adding items to a shopping cart.
          </li>
          <li>
            <em>Improve website performance:</em> We use performance cookies to
            collect information about how visitors interact with our website,
            including the pages they visit most frequently and any error
            messages they may encounter. These cookies help us optimize our
            website and enhance its performance.
          </li>
          <li>
            <em>Personalize your experience:</em> We may use cookies to remember
            your preferences and provide you with a more personalized browsing
            experience. This includes remembering your language preference,
            displaying relevant content, and tailoring advertisements to your
            interests.
          </li>
          <li>
            <em>Analyze website usage:</em> We utilize analytical cookies to
            gather information about website traffic and usage patterns. This
            information helps us understand how visitors engage with our
            website, identify areas for improvement, and develop new features or
            content.
          </li>
          <li>
            <em>Third-party cookies:</em> We may allow third-party service
            providers to place cookies on our website to enable certain features
            or functionalities. These cookies are subject to the respective
            privacy policies of the third-party providers.
          </li>
        </ul>

        <p className="title">Types of cookies we use</p>
        <ul>
          <li>
            <em>Session cookies:</em> These cookies are temporary and are stored
            on your device only during your browsing session. They are essential
            for the proper functioning of the website and are deleted when you
            close your browser.
          </li>
          <li>
            <em>Persistent cookies:</em> Persistent cookies are stored on your
            device for a specific period or until you delete them. They enable
            the website to remember your preferences and settings for future
            visits.
          </li>
          <li>
            <em>Third-party cookies:</em> Third-party cookies are set by domains
            other than our website. We may use third-party services, such as
            analytics providers or advertisers, that utilize cookies to gather
            information about your browsing habits and deliver targeted
            advertisements.
          </li>
        </ul>

        <p className="title">Managing cookies</p>

        <p>
          Most web browsers allow you to control and manage cookies through
          their settings. You can choose to accept or reject cookies, delete
          existing cookies, or be notified when a new cookie is sent to your
          device. However, please note that disabling or deleting certain
          cookies may impact the functionality and performance of our website.
        </p>

        <p>
          For more information on managing cookies in your browser, please refer
          to the relevant documentation or support pages.
        </p>

        <p className="title">Updates to this Cookie Policy</p>
        <p>
          We may update this Cookie Policy from time to time to reflect changes
          in technology or applicable regulations. Any updates will be posted on
          this page, and we encourage you to review this policy periodically.
        </p>

        <p className="title">Contact us</p>

        <p>
          If you have any questions or concerns about our use of cookies or this
          Cookie Policy, please contact us using the contact information
          provided on our website.
        </p>

        <p>
          By continuing to use our website, you agree to the use of cookies as
          described in this Cookie Policy.
        </p>
      </MDBContainer>
      <div className="padded-divider-20"></div>
    </div>
  );
}

export default CookiePolicy;
