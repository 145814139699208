import React from "react";
import "./what-we-do.style.css";
import { MDBContainer } from "mdb-react-ui-kit";
import ServiceCard from "./service-card/service-card";
import background from "../../assets/what-we-do.jpg";

function WhatWeDo() {
  return (
    <div>
      <div className="image-banner" style={{ background: `linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)), url("${background}")`}}>
        <div className="container">
          <div className="col-md-12">
            <div className="lg-text">What we do</div>
            <p className="image-para">
              At Geekible, we specialize in mobile app development, harnessing
              the power of cutting-edge technologies and innovative solutions to
              bring your ideas to life
            </p>
          </div>
        </div>
      </div>

      <div className="padded-divider-20"></div>
      <MDBContainer>
        <p>
          With our expertise in mobile application development, we create
          engaging, user-friendly, and high-performance mobile apps across
          various platforms, including iOS and Android. Our team of experienced
          mobile app developers is passionate about transforming your vision
          into a functional and captivating mobile application. Here's an
          overview of what we do:
        </p>

        <div className="row card-items">
          
            <ServiceCard
              icon="fa fa-laptop"
              title="Strategy and Consulting:"
              text="We work closely with you to understand your business goals, target
              audience, and unique requirements. Our experts provide strategic
              guidance and consulting services to help you define your mobile
              app's concept, features, and monetization strategies."
            />

            <ServiceCard
              icon="fa fa-droplet"
              title="UI/UX Design:"
              text="We believe that the success of a mobile app lies in its user
              experience (UX) and intuitive interface (UI). Our talented
              designers craft visually appealing and user-friendly interfaces,
              ensuring seamless navigation and engaging interactions to enhance
              user satisfaction and retention."
            />

            <ServiceCard
              icon="fa fa-mobile"
              title="Mobile App Development:"
              text="Our skilled developers possess extensive knowledge and expertise
              in native and hybrid app development. Leveraging industry-best
              practices and agile methodologies, we write clean, efficient, and
              scalable code to create robust mobile apps that meet your specific
              requirements."
            />

            <ServiceCard
              icon="fa fa-window-restore"
              title="Cross-platform Development:"
              text="To maximize your app's reach and minimize development costs, we
              offer cross-platform development using frameworks like React
              Native and Flutter. This enables us to build apps that run
              smoothly on multiple platforms, ensuring a consistent experience
              across devices."
            />

            <ServiceCard
              icon="fa fa-server"
              title="Integration and Backend Development:"
              text="We understand the importance of integrating your app with external
              systems and APIs. Our developers seamlessly integrate third-party
              services, databases, and backend systems, enabling your app to
              access and process data efficiently while ensuring security and
              scalability."
            />

            <ServiceCard
              icon="fa fa-clipboard-check"
              title="Quality Assurance and Testing:"
              text="Ensuring the flawless performance of your mobile app is crucial.
              Our QA specialists conduct rigorous testing across various
              devices, screen sizes, and operating systems to identify and
              rectify any bugs or issues. We strive for a smooth and error-free
              user experience."
            />

            <ServiceCard
              icon="fa-brands fa-app-store"
              title="App Store Deployment:"
              text="We guide you through the process of submitting your app to the
              respective app stores, including Apple's App Store and Google Play
              Store. We ensure compliance with guidelines and optimize your
              app's visibility, helping you reach a wider audience and achieve
              higher downloads."
            />

            <ServiceCard
              icon="fa fa-headset"
              title="Maintenance and Support:"
              text="Our commitment to you doesn't end with app deployment. We provide
              ongoing maintenance and support services to ensure your app
              remains up-to-date, secure, and compatible with the latest mobile
              OS versions. We address any issues promptly and implement
              necessary updates or improvements."
            />
        </div>

        <p>
          Partner with Geekible for your application development needs, and let
          us bring your ideas to life with exceptional quality, reliability, and
          innovation. Together, we can create mobile experiences that captivate
          your users and drive business growth.
        </p>
      </MDBContainer>
      <div className="padded-divider-20"></div>
    </div>
  );
}

export default WhatWeDo;
