import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import WhatWeDo from "../components/what-we-do/what-we-do";

function WhatWeDoPage() {
    return (
        <div>
            <Header />
            <WhatWeDo />
            <Footer />
        </div>
    )
}

export default WhatWeDoPage;