import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import PrivacyPolicy from "../components/privacy/privacy";

function PrivacyPage() {
    return <div>
        <Header />
        <PrivacyPolicy />
        <Footer />
    </div>
}

export default PrivacyPage;