import React from "react";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import ContactUsForm from "../components/contact-us-form/contact-us-form";

function ContactUsPage() {
    return <div>
        <Header />
        <ContactUsForm />
        <Footer />
    </div>
}

export default ContactUsPage;