import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import "./footer.style.css";
import google_store from "../../assets/app-stores/google-play-badge.png";
import logo from "../../assets/geekible-logo.jpg";

function Footer() {
  const mailToLink = "mailto:info@geekible.org";
  const telLink = "tel:07949541662"

  return (
    <div>
      <MDBFooter
        bgColor="light"
        className="text-center text-lg-start text-muted"
      >
        <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
          <div className="me-5 d-none d-lg-block">
            <span>Get connected with us on social networks:</span>
          </div>

          <div>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="facebook-f" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="twitter" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="google" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="instagram" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="linkedin" />
            </a>
            <a href="" className="me-4 text-reset">
              <MDBIcon fab icon="github" />
            </a>
          </div>
        </section>

        <section className="">
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">
                  <img src={logo} height={25}/>&nbsp;
                  Geekible Ltd
                </h6>
                <p>
                  Welcome to Geekible, your premier destination for cutting-edge
                  mobile app development solutions.
                </p>
              </MDBCol>

              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                <p>
                  <a href="#!" className="text-reset">
                    Nautical
                  </a>
                  <img src={google_store} height={30}/>
                </p>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Useful links</h6>
                <p>
                  <a href="/privacy-policy" className="text-reset">
                    Privacy Policy
                  </a>
                </p>
                <p>
                  <a href="/cookie-policy" className="text-reset">
                    Cookie Policy
                  </a>
                </p>
                <p>
                  <a href="/unsub" className="text-reset">
                    Unsubscribe
                  </a>
                </p>
              </MDBCol>

              <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                <p>
                  <MDBIcon icon="home" className="me-2" />
                  Ipswich, UK
                </p>
                <p>
                  <MDBIcon icon="envelope" className="me-3" />
                  <a href={mailToLink}>info@geekible.org</a> 
                </p>
                <p>
                  <MDBIcon icon="phone" className="me-3" /> 
                  <a href={telLink}>(07949) 541662</a>
                </p>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
        >
          &copy; {new Date().getFullYear()} Copyright 
          <a className="text-reset fw-bold" href="/">
            &nbsp; Geekible Ltd &nbsp;
          </a>
          - All rights reserved
        </div>
      </MDBFooter>
    </div>
  );
}

export default Footer;
