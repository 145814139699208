import React from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBContainer,
  MDBCollapse,
  MDBNavbarBrand,
} from "mdb-react-ui-kit";
import "./header.style.css";
import geekiblelogo from "../../assets/geekible-logo.jpg";

function Header() {
  return (
    <header>
      <MDBNavbar expand="lg" light bgColor="white" fixed>
        <MDBContainer>
          <MDBNavbarBrand href="/">
            <img src={geekiblelogo} height="30" alt="" loading="lazy" />
            Geekible.org
          </MDBNavbarBrand>
          <MDBCollapse id="navbarCollapse3" navbar>
            <MDBNavbarNav right className="mb-2 mb-lg-0">
              <MDBNavbarItem active>
                <MDBNavbarLink aria-current="page" href="/">
                  Home
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/">About Us</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/what-we-do">What We Do</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/contact-us">Contact Us</MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </header>
  );
}

export default Header;
